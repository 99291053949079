header {
    height: 60px;
    line-height: 60px;
    width: 100%;
    padding: 0;
    position: fixed;
    z-index: 999999;
    top: 0;
    transition: .45s;
    -moz-box-shadow: 0 3px 9px 0 rgba(0,0,0,.10), 0 10px 30px 0 rgba(0,0,0,.15);
    -webkit-box-shadow: 0 3px 9px 0 rgba(0,0,0,.10), 0 10px 30px 0 rgba(0,0,0,.15);
    box-shadow: 0 3px 9px 0 rgba(0,0,0,.10), 0 10px 30px 0 rgba(0,0,0,.15);
    background: #f8f7f2;
}

.inner-header {
    display: flex;
    max-width: 100%;
    max-width: 1360px;
    margin: 0 auto;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
}

.first-flex, .second-flex {
    /* width: calc(50% - 50px); */
    width: 50%;
    height: 60px;
}

.second-flex li span {
    width: 20px;
    height: 20px;
}

.logo {
    width: 100px;
    height: 60px;
    background-image: url(../../img/newlogo2.jpg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    /* margin: 0 auto; */
    float: left;
}

header ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

header ul li {
    float: left;
    color: black;
    margin: 17px 10px;
    padding: 3px 0;
    transition: all ease 300ms;
    cursor: pointer;
    text-transform: capitalize;
    user-select: none;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    height: 20px;
    line-height: 20px;
    border-bottom: 2px solid transparent;
    opacity: .8;
}

header ul li:hover {
    /* border-bottom: 2px solid rgba(34, 128, 128, 0.75); */
    opacity: 1;
    color: rgb(21, 105, 105);
    border-radius: 4px;
    margin: 17px 0;
    padding: 3px 10px;
}

/* call to action */
.shop-icons {
    display: block;
    background: var(--glacier);
    color: var(--dark-blue);
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    cursor: pointer;
    margin: 13px 10px;
    outline-style: none;
    font-size: 0.85rem;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-transform: lowercase;
    border: 2px solid turquoise;
    border-radius: 17px;
    transition: all ease-out .5s;
}

.facebook-header {
    color: #315191;
    border-color: #4267B2;
    padding: 0;
    margin: 13px 0;
    width: 30px;
    text-align: center;
}

.location-header {
    color: #ca3a3a;
    border-color: #ca3a3a;
    padding: 0;
    margin: 13px 10px;
    width: 30px;
    text-align: center;
}

.shop-icons:hover {
    background: turquoise;
}

.facebook-header:hover {
    background: #4267B2;
    color: var(--glacier);
}

.location-header:hover {
    background: #ca3a3a;
    color: var(--glacier);
}

.shop-icons:active {
    background: lightgreen;
    border-color: lightgreen;
}

.facebook-header:active {
    background: #315191;
    border-color: #315191;
}

.location-header:active {
    background: #b42e2e;
    border-color: #b42e2e;
}

.shop-icons .first {
    display: inline;
}

.right {
    float: right;
}

.small-viewport-header {
    display: block;
}

.hamburger {
    background-color: transparent;
    overflow: hidden;
    width: 60px;
    height: 60px;
    margin: 0 10px;
    padding: 0;
    outline: none;
    background-image: url(../../icons/hamburger.png);
    background-size: 26px auto;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    transition: ease-out all .2666s;
    display: none;
    border-bottom: 2px solid transparent;
    opacity: 1;
}

.hamburger:hover {
    padding: 0;
    border-bottom: 2px solid transparent;
    opacity: 1;
}

.small-menu-container {
    background: #f8f7f2;
    padding-top: 10px;
    display: none;
    position: absolute;
    height: calc(100vh - 60px);
    top: 60px;
    width: 100%;
    max-width: 320px;
    left: 0;
    z-index: 999999;
    transition: .3s;
    -moz-box-shadow: 0 3px 6px 0 rgba(0,0,0,.05), 0 10px 30px 0 rgba(0,0,0,.10);
    -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,.05), 0 10px 30px 0 rgba(0,0,0,.10);
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.05), 0 10px 30px 0 rgba(0,0,0,.10);
}

.small-menu-container li {
    background: #f8f7f2;
    width: calc(100% - 30px);
    height: 20px;
    line-height: 20px;
    text-align: left;
    margin: 12px 15px;
    border-bottom: 2px solid transparent;
    color: var(--dark-blue);
    font-size: 1rem;
    font-weight: bolder;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    font-family: Arial;
    -webkit-transition: all 0.3s cubic-bezier(0.4, 0.26, 0, 0.97);
    -o-transition: all 0.3s cubic-bezier(0.4, 0.26, 0, 0.97);
    transition: all 0.3s cubic-bezier(0.4, 0.26, 0, 0.97);
}

.small-menu-container li:hover {
    border-bottom: 2px solid var(--dark-secondary);
    padding-bottom: 8px;
}

.is-active li, .is-active li:hover {
    border-bottom: 2px solid rgba(34, 128, 128, 0.75);
    padding-bottom: 18px;
    opacity: 1;
    background: #f8f7f2;
}

.is-active li:hover {
    border-radius: 0;
}

@media only screen and (max-width: 700px) {
    .small-menu-container li {
        padding: 5px 0;
    }
}

@media only screen and (max-width: 550px) {
    .small-menu-container {
        max-width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .first {
        display: none !important;
    }

    .shop-icons {
        padding: 0;
        width: 30px;
        text-align: center;
        margin: 13px 5px;
    }
}

@media only screen and (max-width: 930px) {
    .hamburger {
        display: block;
        margin: 0;
        width: 55px;
    }

    .viewport-wrapper {
        display: none;
    }
}