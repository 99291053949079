.under-construction {
    width: calc(100% - 40px);
    padding: 20px;
    height: 500px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    margin-top: 60px;
}

.shop-header {
    width: calc(100% - 40px);
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 60px;
    max-width: 1200px;
    display: none;
}

.shop-header li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    text-transform: capitalize;
    font-weight: 450;
    padding: 0 5px;
    height: 34px;
    line-height: 34px;
    margin: 13px 0;
    border-radius: 5px;
    user-select: none;
}

.shop-header span {
    margin-left: 5px;
    user-select: none;
    cursor: pointer;
    /* background: var(--oceanblue); */
    padding: 1px 2px;
    border-radius: 2px;
    font-size: 13px;
}

.cat-select {
    background: #f0f0f0;
    transition: ease 300ms all;
}

.cat-select:hover {
    background: var(--oceanblue);
}

.shop-type {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
    width: calc(100% - 40px);
    max-width: 1200px;
    margin: 0 auto;
    display: none;
}

.shop-item {
    min-height: 425px;
    background: #ffffff;
    border: 1px solid #dddddd;
}

.shop-item * {
    user-select: none;
}

.product-img {
    width: 100%;
    height: 300px;
    background-image: url(../../img/productimg.png);
    background-repeat: no-repeat;
    background-size: 100% 300px;
    cursor: pointer;
}

.product-name {
    width: calc(100% - 30px);
    padding: 15px;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 20px;
}

.product-cat {
    width: calc(100% - 30px);
    padding: 0 15px;
    font-size: 11px;
    text-transform: uppercase;
    font-style: italic;
}

.product-price {
    width: calc(100% - 30px);
    padding: 15px;
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
}

.product-actions {
    display: flex;
    width: calc(100% - 30px);
    margin: 0 auto;
    height: 40px;
    margin-bottom: 15px;
}

.product-add {
    width: calc(100% - 55px);
    margin: 0;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    background: var(--oceanblue);
    font-size: 15px;
    font-weight: 450;
}

.product-fav {
    width: 38px;
    height: 38px;
    margin-left: 15px;
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid #dddddd;
    cursor: pointer;
    vertical-align: middle;
    line-height: 38px;
    text-align: center;
    color: var(--secondary-dark);
    transition: all ease 300ms;
}

.product-fav:hover {
    color: rgb(248, 103, 158);
}