#target-page {
    width: 100%;
    background: #ffffff;
    margin-top: 60px;
    color: var(--dark-secondary);
}

.target-page-header {
    width: 100%;
    padding: 75px 0;
    background: var(--oceanblue);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
}

.inner-target {
    width: calc(100% - 40px);
    max-width: 980px;
    margin: 0 auto;
}

.target-page-header h1 {
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: clamp(0.75rem, 4.5vw + 0.85rem, 3.25rem);
}

:is(.target-page-header) > h3 {
    margin: 0;
    padding: 0;
}

.target-content {
    width: 100%;
    padding-top: 25px;
}

.target-content p {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    line-height: 1.5rem;
    /* indent all subsequent text lines except the first one to account for the icon space */
    padding-left: 16px;
    text-indent: -16px;
}

.target-content p::before {
    content: "\2713";
    color: cyan;
    font-weight: bolder;
}

:is(.inner-target) button {
    height: 54px;
    line-height: 54px;
    padding: 0 35px;
    background: #5de2eb;
    margin: 0 auto;
    margin-top: 100px;
    color: var(--dark-secondary);
    outline-style: none;
    font-size: 1.25rem;
    font-weight: 500;
    text-transform: capitalize;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border: none;
    user-select: none;
    cursor: pointer;
    transition: all ease 300ms;
    display: block;
}

:is(.inner-target) button:hover {
    background: turquoise;
}

:is(.inner-target) button:active {
    background: cyan;
}