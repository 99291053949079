.landing-header {
    width: 100%;
    height: calc(75vh - 80px);
    margin-top: 60px;
    background-image: url(../../s3/pets2.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    border-bottom: 3px solid var(--dark-blue);
}

.landing-inner-container {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(-90deg, rgba(255, 255, 255, .4) 0%, rgb(64, 224, 208, .6) 100%);
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
    justify-items: flex-end;
}

.bottom-section {
    padding: 10px 10px;
    margin: 0 auto;
    height: 34px;
    background: var(--dark-blue);
    border-radius: 6px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin-bottom: -27px;
    border-radius: 27px;
    -moz-box-shadow: 0 3px 12px 0 rgba(0,0,0,.15), 0 12px 33px 0 rgba(0,0,0,.15);
    -webkit-box-shadow: 0 3px 12px 0 rgba(0,0,0,.15), 0 12px 33px 0 rgba(0,0,0,.15);
    box-shadow: 0 3px 12px 0 rgba(0,0,0,.15), 0 12px 33px 0 rgba(0,0,0,.15);
}

:is(.bottom-section) h1 {
    padding: 0;
    margin: 0 20px;
    text-align: center;
    max-width: calc(100% - 20px);
    font-weight: 350;
    font-size: clamp(2rem, 2vw + 1rem, 0.75vw);
    font-size: 26px;
    user-select: none;
    text-transform: none;
    color: var(--glacier);
}

:is(.bottom-section) button {
    height: 36px;
    line-height: 36px;
    padding: 0 15px;
    -moz-box-shadow: 0 3px 9px 0 rgba(0,0,0,.10), 0 10px 30px 0 rgba(0,0,0,.15);
    -webkit-box-shadow: 0 3px 9px 0 rgba(0,0,0,.10), 0 10px 30px 0 rgba(0,0,0,.15);
    box-shadow: 0 3px 9px 0 rgba(0,0,0,.10), 0 10px 30px 0 rgba(0,0,0,.15);
    background: turquoise;
    margin: 0;
    color: var(--dark-blue);
    outline-style: none;
    font-size: 1rem;
    font-weight: 450;
    text-transform: lowercase;
    border: none;
    border-radius: 18px;
    user-select: none;
    cursor: pointer;
    transition: all ease 300ms;
    vertical-align: middle;
}

.blink-me {
    animation: blink 1s linear infinite;
}

:is(.bottom-section) button:hover {
    background: aquamarine;
}

:is(.bottom-section) button:active {
    background: cyan;
}

.reference {
    width: 100%;
    margin-top: 27px;
}

@media only screen and (max-width: 760px) {
    .landing-header {
        background-size: auto 100%;
    }
}

@media only screen and (max-width: 650px) {
    .bottom-section {
        padding: 10px 10px;
        width: calc(100% - 20px);
        height: auto;
        flex-direction: column;
        border-radius: 0;
    }

    :is(.bottom-section) button {
        margin-top: 10px;
    }

    .landing-header {
        border-bottom: 0;
    }
}