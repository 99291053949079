.contact-page {
    background: #ffffff;
    margin-top: 60px;
    width: 100%;
    padding: 50px 0;
    padding-bottom: 0;
    font-family: 'Nunito-Regular';
    font-weight: bold;
}

.contact-top-section {
    width: calc(100% - 20px);
    min-height: 400px;
    background-image: url(../../img/RSS.png);
    background-repeat: no-repeat;
    background-size: auto 400px;
    background-position: 100% 0;
    padding: 20px 0;
    max-width: 1360px;
    margin: 0 auto;
    margin-bottom: 50px;
    display: flex;
    align-items: flex-end;
}

.absolute-text-container {
    width: 100%;
    padding: 0;
}

.absolute-text-container h1 {
    margin: 20px 0;
    padding: 0;
    font-family: 'Barlow Condensed';
    text-align: left;
}

.absolute-text-container p {
    margin: 10px 0;
    padding: 0;
    text-align: left;
    font-size: 18px;
    line-height: 22px;
    max-width: 50%;
}

.inner-contact {
    width: calc(100% - 26px);
    border: 3px solid #000000;
    padding: 0;
    max-width: 1360px;
    margin: 0 auto;
}

.contact-header {
    width: calc(100% - 40px);
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    text-align: left;
    text-transform: capitalize;
    font-size: 1.75rem;
    border-bottom: 3px solid #000000;
}

.map-section {
    width: calc(100% - 40px);
    padding: 20px;
    height: 444px;
    border-bottom: 3px solid #000000;
}

.address-section {
    width: calc(100% - 40px);
    padding: 20px;
    text-align: left;
    text-transform: capitalize;
    font-size: 18px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.address-section div p {
    margin: 0;
    margin-bottom: 7px;
}

.form-section {
    width: 100%;
    margin-top: 100px;
}

.inner-form-section {
    width: calc(100% - 20px);
    max-width: 1320px;
    margin: 0 auto;
    background: #ffffff;
    padding: 25px 0;
}

@media only screen and (max-width: 760px) {
    .contact-page * {
        letter-spacing: -1px;
    }

    .contact-top-section {
        width: calc(100% - 20px);
        min-height: 400px;
        background-image: url(../../img/RSS.png);
        background-repeat: no-repeat;
        background-size: auto 240px;
        background-position: 100% 0;
        padding: 0;
        max-width: 1360px;
        margin: 0 auto;
        margin-bottom: 50px;
        display: flex;
        align-items: flex-end;
    }

    .absolute-text-container p {
        max-width: 85%;
    }

}
