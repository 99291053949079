.product-header {
    width: calc(100% - 40px);
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 60px;
    max-width: 1200px;
}

.product-header li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    text-transform: capitalize;
    font-weight: 450;
    padding: 0 5px;
    height: 34px;
    line-height: 34px;
    margin: 13px 0;
    border-radius: 5px;
    user-select: none;
}

.product-header a {
    color: var(--secondary-dark);
}

.product-type {
    width: calc(100% - 40px);
    max-width: 1200px;
    margin: 0 auto;
    min-height: 450px;
}

.product-half {
    width: 50%;
    float: left;
    min-height: 420px;
}

.single-img {
    height: 400px;
    background-image: url(../../img/productimg.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.single-name {
    width: calc(100% - 30px);
    padding: 15px;
    text-transform: capitalize;
    font-size: 30px;
    user-select: none;
}

.single-cat {
    width: calc(100% - 30px);
    padding: 0 15px;
    font-size: 11px;
    text-transform: uppercase;
    font-style: italic;
    user-select: none;
}

.single-price {
    width: calc(100% - 30px);
    padding: 15px;
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
    user-select: none;
}

.single-description {
    width: calc(100% - 30px);
    padding: 0 15px;
    user-select: none;
}

.single-actions {
    display: flex;
    width: calc(100% - 30px);
    margin: 20px auto;
    height: 40px;
    user-select: none;
}

@media only screen and (max-width: 760px) {
    .product-half {
        width: 100%;
    }
}
