.not-found {
    width: calc(100% - 20px);
    padding: 20px 10px;
    padding-top: 100px;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    user-select: none;
    font-weight: bold;
    background: #ffffff;
    color: #000000;
}

.four-zero-four {
    font-size: 12vw;
    font-family: Arial, Helvetica, sans-serif;
}

.not-found-info {
    font-size: 20px;
    font-weight: 350;
    margin: 10px 0;
}

.not-found-info span {
    font-weight: bold;
    border-bottom: 2px solid #000000;
    cursor: pointer;
}

@media only screen and (max-width: 760px) {
    .four-zero-four {
        font-size: 36vw;
    }
}
