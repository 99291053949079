:root {
    --dark-blue: #17152b;
    --main-button-color: #5c6ac4;
    --secondary-button-color: #3e488b;
    --optional-button-color: #1f2657;
    --glacier: #f0f0f0;
    --oceanblue: #A9EDFF;
    --dark: #19171c;
    --dark-secondary: #18181b;
}

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&display=swap');

@font-face {
    font-family: Nunito-Regular;
    src: url('fonts/Nunito-Regular.otf') format('otf'),
         url('fonts/Nunito-Regular.woff') format('woff'),
         url('fonts/Nunito-Regular.ttf') format('truetype');
    font-display: swap;
}

body {
    margin: 0;
    padding: 0;
    background: #ffffff;
}

.interactive, .explore {
    background: transparent;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    color: #03061a;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    font-size: 1.30em;
    -webkit-font-smoothing: antialiased;
    -webkit-transition: 150ms ease;
    -webkit-transition-property: background-color, border-color, color, -webkit-box-shadow;
    transition-property: background-color, border-color, color, -webkit-box-shadow;
    transition-property: background-color, border-color, box-shadow, color;
    transition-property: background-color, border-color, box-shadow, color, -webkit-box-shadow;
    text-align: center;
    user-select: none;
    cursor: pointer;
    outline-style: none;
    border: none;
    transition: all 0.3s ease;
    transform: scale(1);
    text-transform: lowercase;
    padding: 0;
}

.interactive:hover, .explore:hover {
    color: var(--glacier);
    transform: scale(1.1);
}

.interactive:hover span, .explore:hover span {
    color: var(--glacier);
}

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: var(--glacier);
}

::-webkit-scrollbar-thumb {
    background: #666666;
    border: 2px solid var(--glacier);
    border-radius: 6px;
    -webkit-border-radius: 6px;
}

@-webkit-keyframes blink {
    0% {
        color: var(--dark-blue);
    }
    50% {
        color: transparent;
    }
    100% {
        color: var(--dark-blue);
    }
}

@keyframes blink {
    0% {
        color: var(--dark-blue);
    }
    50% {
        color: transparent;
    }
    100% {
        color: var(--dark-blue);
    }
}

@-webkit-keyframes blink-light {
    0% {
        color: var(--glacier);
    }
    50% {
        color: transparent;
    }
    100% {
        color: var(--glacier);
    }
}

@keyframes blink-light {
    0% {
        color: var(--glacier);
    }
    50% {
        color: transparent;
    }
    100% {
        color: var(--glacier);
    }
}

.blink {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;

    -webkit-animation-direction: normal;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: blink;
    -webkit-animation-timing-function: ease-in-out;

    animation-direction: normal;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: blink;
    animation-timing-function: ease-in-out;
}

.blink-light {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;

    -webkit-animation-direction: normal;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: blink-light;
    -webkit-animation-timing-function: ease-in-out;

    animation-direction: normal;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: blink-light;
    animation-timing-function: ease-in-out;
}