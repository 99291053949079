.inner-button {
    width: calc(100% - 48px);
    padding: 0 20px;
    height: 50px;
    line-height: 50px;
    margin: 4px;
    background: #ffffff;
    color: var(--dark-secondary);
    transition: all ease .5;
    user-select: none;
}

.activated {
    background: turquoise;
    color: var(--dark-secondary);
    transition: all ease .5;
}

.disabled-button:hover .activated {
    background: aquamarine;
    color: var(--dark-secondary);
    transition: all ease .5;
}

.disabled-button:active .activated {
    background: cyan;
}

.disabled-button {
    width: calc(100% - 26px);
    max-width: 540px;
    margin: 0 auto;
    height: 58px;
    padding: 0;
    border: 3px solid var(--dark-secondary);
    background: #ffffff;
    outline: none;
    text-transform: capitalize;
    transition: all ease .5;
    font-size: 1.25rem;
    font-weight: 500;
    cursor: not-allowed;
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.ready-button {
    cursor: pointer;
    color: var(--dark-secondary);
}

:is(.grid-wrapper) div {
    font-weight: 600;
    color: #5f5f5f;
    font-size: 1rem;
    background: #ffffff;
    box-shadow: 0px 6px 12px 1px #ccc;
    transition: all ease 300ms;
    padding-top: 5px;
}

:is(.grid-wrapper) div > label {
    color: var(--dark-secondary);
    padding: 0 10px;
    line-height: 20px;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
}

:is(.grid-wrapper) div > label > span {
    font-weight: 100;
}

:is(.grid-wrapper) div > select {
    width: 100%;
    margin: 0;
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
    font-family: 'Open Sans', 'Helvetica Neue', 'Segoe UI', 'Calibri', 'Arial', sans-serif;
    font-size: 18px;
    color: var(--dark-secondary);
    font-weight: 300;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("http://cdn.onlinewebfonts.com/svg/img_295694.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 16px auto;
}

select option {
    font-family: 'Open Sans', 'Helvetica Neue', 'Segoe UI', 'Calibri', 'Arial', sans-serif;
    font-size: 18px;
    color: var(--dark-secondary);
    font-weight: 300;
}

.classifier-wrapper {
    width: 100%;
    padding: 50px 0;
    background: #ffffff;
}

.big-wrapper {
    width: 100%;
    max-width: 1420px;
    margin: 0 auto;
}

.small-wrapper {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
}

.small-wrapper div div span {
    float: right;
    transition: all ease 300ms;
    transform: translateX(5px);
}

.small-wrapper div div:hover span {
    transform: translateX(15px);
}

.grid-wrapper {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    padding: 0 10px;
}

@media only screen and (max-width: 760px) {

}

@media only screen and (max-width: 980px) {

    .big-wrapper, .small-wrapper {
        width: 100%;
    }

    :is(.small-wrapper) button {
        width: calc(100% - 20px);
        margin: 0 10px;
    }

    .grid-wrapper {
        grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
        grid-gap: 10px;
    }

    .classifier-wrapper {
        padding: 10px 0;
    }

    .small-wrapper {
        margin-top: 10px;
    }
}