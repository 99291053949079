.brand-slide {
    width: 100%;
    padding: 50px 0;
    background: #fff;
    display: inline-block;
}

.brand-wrapper {
    color: var(--dark);
    display: grid;
    grid-gap: 30px;
    max-width: 1440px;
    padding: 50px 0;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.brand-wrapper .div-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    transition: ease all .5s;
}

:is(.brand-wrapper) .img1, .img2, .img3 {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 100px auto;
    background-position: center;
    -moz-box-shadow: 0 3px 7px 0 rgba(0,0,0,.13), 0 7px 23px 0 rgba(0,0,0,.18);
    -webkit-box-shadow: 0 3px 7px 0 rgba(0,0,0,.13), 0 7px 23px 0 rgba(0,0,0,.18);
    box-shadow: 0 3px 7px 0 rgba(0,0,0,.13), 0 7px 23px 0 rgba(0,0,0,.18);
}

:is(.brand-wrapper) .div-wrapper:hover {
    margin-top: -15px;
    cursor: pointer;
}

.img1 {
    background-image: url(..//icons/2.png);
}

.img2 {
    background-image: url(..//icons/4.png);
}

.img3 {
    background-image: url(../img/grid3.png);
}

.brand-wrapper h1 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    font-size: 1.75rem;
    padding: 10px 20px;
    margin: 40px 0;
    min-height: 45px;
    text-transform: none;
}

.brand-wrapper p {
    margin: 0 auto;
    width: calc(100% - 20px);
    text-align: center;
    font-size: 18px;
}

.service-advertise {
    width: 100%;
    padding-top: 150px;
    background: var(--oceanblue);
    color: var(--dark-secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}

.service-advertise p {
    width: calc(100% - 40px);
    max-width: 766px;
    margin: 0 auto;
    text-align: center;
    font-size: 18px;

}

:is(.service-advertise) .small-advertiser {
    background: #ffffff;
    width: calc(100% - 42px);
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    border-radius: 9px 9px 0 0;
}

.advertise-box {
    width: 50%;
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
}

.service-wrapper {
    background: #ffffff;
    width: calc(100% - 100px);
    padding: 100px 50px;
}

.services-panel {
    padding: 50px 20px;
    overflow-y: hidden;
    overflow-x: scroll;
    max-width: 1340px;
    margin: 0 auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    position: relative;
}

.services-panel::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    background: transparent;
}
.services-panel::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 3ex;
    -webkit-border-radius: 3ex;
}

:is(.services-panel) div {
    background: var(--dark-secondary);
    color: var(--glacier);
    height: 70px;
    width: 260px;
    margin: 0 10px;
    display: inline-flex;
    position: relative;
    overflow-y: visible;
    overflow-x: hidden;
    white-space: normal;
    transition: .25s;
    cursor: pointer;
    text-align: left;
    font-weight: 450;
    padding: 15px 20px;
    font-size: 15px;
}

.services-panel div:hover {
    background: #dfdfdf;
    text-decoration: underline;
    color: var(--dark-secondary);
}

.services-panel div p {
    padding: 0;
    margin: 0;
}

.absolute-button-left, .absolute-button-right {
    position: absolute;
    width: 50px;
    height: 120px;
    margin-top: 40px;
    border: none;
    outline: none;
    cursor: pointer;
}

.absolute-button-left {
    width: 55px;
    background: #ffffff;
    text-align: center;
    left: 0;
    color: var(--dark-secondary);
}

.absolute-button-right {
    width: 60px;
    background: #ffffff;
    text-align: center;
    right: 0;
    color: var(--dark-secondary);
}

@media only screen and (max-width: 980px) {
    .brand-wrapper {
        grid-gap: 75px;
    }

    .services-panel {
        padding: 60px 20px;
    }

    .absolute-button-left, .absolute-button-right {
        margin-top: 55px;
    }
}

@media only screen and (max-width: 760px) {
    .brand-wrapper h1 {
        letter-spacing: -1px;
    }
}

@media only screen and (max-width: 600px) {
    .absolute-button-left, .absolute-button-right {
        display: none;
    }
}