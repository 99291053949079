.formdivision {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    color: #000000;
    font-family: 'Nunito-Regular';
}

.formdivision h1 {
    text-align: left;
    font-family: 'Barlow Condensed';
    padding: 0;
    margin: 0;
    margin-bottom: 100px;
    font-size: 1.75rem;
    line-height: 2rem;
    font-weight: 300;
    text-transform: none;
    color: #000000;
}

form * {
    color: #000000;
    font-family: 'Nunito-Regular';
}

input {
    width: 100%;
    padding: 10px 0;
    font-size: 18px;
    text-align: left;
    height: 24px;
    line-height: 24px;
    outline-style: none;
    border: none;
    border-bottom: 1px solid #000000;
    margin: 10px 0;
    margin-bottom: 50px;
    border-radius: unset;
}

label {
    color: #000000;
    font-size: 18px;
    display: block;
}

textarea {
    padding: 10px 0;
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    min-height: 48px;
    outline-style: none;
    border: 1px solid transparent;
    resize: none;
    margin-bottom: 50px;
    margin-top: 10px;
    border-bottom: 1px solid #000000;
    border-radius: unset;
}

input:focus, textarea:focus {
    border-bottom: 3px solid #000000;
    margin-bottom: 48px;
}

textarea:focus {
    min-height: 144px;
}

.send-message {
    width: calc(100% - 12px);
    padding: 3px;
    text-align: left;
    font-size: 18px;
    height: 52px;
    font-weight: bold;
    font-family: 'Nunito-Regular';
    color: #000000;
    border: 3px solid #000000;
    user-select: none;
    cursor: pointer;
}

.send-message-inner {
    width: calc(100% - 40px);
    padding: 0 20px;
    height: 52px; 
    line-height: 48px;
    text-transform: capitalize;
    transition: all ease 300ms;
}

.send-message:hover .send-message-inner {
    background: #5de2eb;
}

.send-message span {
    float: right;
    transition: all ease 300ms;
    transform: translateX(5px);
}

.send-message:hover span {
    transform: translateX(15px);
}

.send-message span * {
    vertical-align: middle;
}

@media only screen and (max-width: 1000px) {
    
}

@media only screen and (max-width: 600px) {
    .send-message-inner {
        line-height: 52px;
    }
}