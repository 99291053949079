.footer * {
    user-select: none;
    font-family: 'Nunito-Regular';
    font-weight: bold;
}

.footer {
    width: 100%;
    height: auto;
    padding: 0;
    color: #000000;
    background-color: #ffffff;
    /* background-image: url(../../img/mapcover.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto; */
}

.social-plugins {
    width: calc(100% - 20px);
    max-width: 1360px;
    margin: 0 auto;
    padding: 120px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
}

.social-plugins div {
    border: 3px solid black;
    padding: 3px;
    cursor: pointer;
    display: flex;
    transition: all 0.25s ease;
}

.social-plugins div .separator {
    width: 3px;
    padding: 0;
    height: 80px;
    border: none;
    background-color: black;
    margin: 0 3px;
}

.social-plugins div:hover .separator {
    background-color: black;
}

.social-plugins div > div {
    border: none;
    line-height: 74px;
}

.social-plugins div > div {
    font-size: 18px;
    width: calc(100% - 76px);
}

.social-plugins div > div > span {
    margin-left: 15px;
}


.social-plugins div .facebook {
    width: 74px;
    height: 74px;
    border: 0;
    margin-left: 0;
    background-image: url(../../img/social/facebook.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 40px;
}

.social-plugins div .instagram {
    width: 74px;
    height: 74px;
    border: 0;
    background-image: url(../../img/social/instagram.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 40px;
}

.social-plugins div .whatsapp {
    width: 74px;
    height: 74px;
    border: 0;
    background-image: url(../../img/social/whatsapp.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 40px;
}

.social-plugins .x:hover > * {
    background-color: #5de2eb;
}

.social-plugins .y:hover > * {
    background-color: #5de2eb;
}

.social-plugins .z:hover > * {
    background-color: #5de2eb;
}

.inner-footer {
    width: calc(100% - 20px);
    max-width: 1360px;
    margin: 0 auto;
    padding: 30px 0;
    border-top: 3px solid black;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 0;
}

.inner-footer div {
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
}

.inner-footer div p {
    margin: 0;
}

.spacer {
    width: 100%;
    padding: 15px 0;
}

.lowercase {
    text-transform: lowercase;
}

.email {
    padding-bottom: 5px;
    transition: all .3 ease-out;
    width: 100%;
}

.email a {
    text-decoration: none;
    color: #000000;
}

.phone a {
    color: #000000;
    text-decoration: none;
}

.inner-footer div p span {
    float: right;
}

.convect {
    text-transform: none;
}

#map-section {
    width: 100%;
    min-height: 555px;
}

#map-section .mapouter {
    position: relative;
    width: 100%;
    height: 555px;
}

@media only screen and (max-width: 1260px) {
    .inner-footer {
        grid-template-columns: repeat(auto-fit, minmax(calc(50% - 25px), 1fr));
        grid-gap: 50px;
    }

    .inner-footer div p {
        margin: 0 75px;
    }

    .convect {
        width: 100%;
        text-align: left !important;
    }
}

@media only screen and (max-width: 1060px) {
    .inner-footer {
        grid-template-columns: repeat(auto-fit, minmax(calc(50% - 20px), 1fr));
        grid-gap: 40px;
    }

    .inner-footer div p {
        margin: 0 25px;
    }

    .social-plugins {
        width: calc(100% - 20px);
        max-width: 1360px;
        margin: 0 auto;
        padding: 80px 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(calc(50% - 10px), 1fr));
        grid-gap: 40px;
    }

    .social-plugins div > div > span {
        letter-spacing: -1px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 760px) {
    .footer * {
        letter-spacing: -1px;
    }

    .inner-footer {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        grid-gap: 60px;
        width: calc(100% - 40px);
    }

    .inner-footer div p {
        margin: 0;
    }

    .social-plugins {
        grid-template-columns: repeat(auto-fit, minmax(calc(100% - 10px), 1fr));
    }
}


