#tarife {
    width: 100%;
    background: var(--glacier);
    padding: 20px 0;
    padding-bottom: 1px;
}

.board {
    width: calc(100% - 40px);
    max-width: 780px;
    margin: 0 auto;
    padding: 0;
    margin-top: 60px;
}

.board .wrapper-item {
    margin-bottom: 20px;
    border: 1px solid #e7e7e7;
    border-bottom: none;
    display: flex;
    flex-direction: column;
}

.board .wrapper-item .section-divider {
    background: rgb(182, 255, 248);
    color: var(--dark-blue);
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
}

.board .wrapper-item .item {
    border-bottom: 1px solid #e7e7e7;
    background: #ffffff;
    color: var(--dark-blue);
    font-weight: 450;
    display: flex;
    justify-content: space-between;
    min-height: 41px;
}

.board .wrapper-item .section-divider .category, .board .wrapper-item .item .category {
    flex: 0 0 calc(100% - 141px);
    padding: 10px;
}

.board .wrapper-item .section-divider .category {
    padding: 15px 10px;
}

.board .wrapper-item .item .category {
    flex: 0 0 calc(100% - 146px);
    padding: 10px;
    padding-left: 15px;
    transition: all ease .333s;
}

.board .wrapper-item .item .category:hover {
    background: #f8f8f8;
}

.board .wrapper-item .section-divider .price, .board .wrapper-item .item .price {
    border-left: 1px solid #e7e7e7;
    flex: 0 0 100px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: right;
    align-content: center;
    justify-items: center;
    justify-content: center;
}

.board .wrapper-item .item .price {
    background: #f8f8f8;
    text-align: right;
}

.board .wrapper-item .section-divider .price {
    background: rgb(74, 243, 226);
    border-left: none;
    flex: 0 0 101px;
}