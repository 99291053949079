.top {
    background-color: white;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    padding: 15px;
    margin: 0;
}

h1 {
    margin: 0 auto;
    padding: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bolder;
    text-align: center;
    text-transform: capitalize;
    font-size: clamp(0.9rem, 6vw + 1rem, 4rem);
}

.info-column {
    background-color: white;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}

.welcomeslider {
    height: 60vh;
    width: 100%;
}

.rightsection {
    background-image: url(../../s3/cabinet.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    aspect-ratio: 16 / 9;
    transition: transform .8s;
}

.rightsection:hover {
    transform: scale(1.05);
}

.button-wrap {
    display: flex;
    bottom: 0;
    width: 100%;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
}

.overlay {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(0, 255, 85, 0.2));
}

.overlay:hover {
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.1), rgba(0, 255, 255, 0.1));
}

@media only screen and (max-width: 1200px) {
    
}

@media only screen and (max-width: 550px) {

}


